import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddUserButton from './AddUserButton';
import UpdatePassword from './UpdatePassword';
import EditUserInputs from '../../inputs/EditUserInputs';
import DeleteUserButton from '../../inputs/DeleteUserButton';
import SearchUser from './SearchUser';
import DropBoxUser from '../../inputs/FileUploadButton/DropBoxUser';
import NotificationSettings from './NotificationSettings';
import {
	lookupUserById,
} from '../../actions/lookups';
import {
	forgotpassword,
	changepassword,
} from '../../actions/authentication';
import {
	addFlashMessage,
	deleteFlashMessages,
	change_page,
} from '../../actions/toggleMenu';

class UserSingle extends Component {
	constructor(props) {
		super(props);

		this.state = { value: '' };
		this.onClick = this.onClick.bind(this);
		this.onClickChangePassword = this.onClickChangePassword.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		this.setState({ value: e.target.value });
	}

	onClick(e) {
		const self = this;
		if (this.props.single_user > 0) {
			var user = _.find(this.props.all_users, { id: Number(this.props.single_user) }) || [];
		}

		this.props.forgotpassword(user.user_name).then((resp) => {
			if (resp == 'password reset has been sent') {
				this.props.addFlashMessage({
					type: 'success',
					text: 'Password reset has been sent!',
				});
				setTimeout(() => { self.props.deleteFlashMessages(); }, 5000);
			} else {
				this.props.addFlashMessage({
					type: 'error',
					text: resp.errors.loginError,
				});
				setTimeout(() => { self.props.deleteFlashMessages(); }, 3000);
				this.setState({ isLoading: false });
			}
		});
	}

	onClickChangePassword(e) {
		const self = this;
		if (this.props.single_user > 0) {
			var user = _.find(this.props.all_users, { id: Number(this.props.single_user) }) || [];
		}

		this.props.changepassword(user.user_name);
	}
 
	render() {
		if (this.props.single_user > 0) {
			var user = _.find(this.props.all_users, { id: Number(this.props.single_user) }) || [];
		}

		const { installation } = this.props;
		return (
			<div className="row">
				<div className="col-md-4">
					<div className="card space-top-1">
						<div className="card-header bg-grey text-white text-capitalize">
							<button className="btn btn-dark form-control">User Photo/Avatar (optional)</button>
						</div>

						<div className="card-text text-center">
							<img src={user.avatar} className={'avatar-lg'} style={{ maxWidth: '200px' }} />

						</div>
						<div className="card-footer">
							<DropBoxUser {...this.props} user={user.id} siteid={`${installation.id}_${user.user_name}`} />
						</div>
					</div>
				</div>
				<div className="col-md-8">

					<div className="card space-top-1">
						<div className="card-header text-capitalize">
							<div className="container-fluid bg-grey p-3">
								<h1 className="mw-90 fs-2">iPacket Account Management Has Been Updated!</h1>
								<h2 className="fs-2">
									Manage the following account settings on the new{' '}
									<a
										className="link-primary"
										target="_blank"
										href={
											window.location.hostname === 'app.ipacketrecon.com'
											? 'https://auth.autoipacket.com/account'
											: 'https://auth.autoipacket-stg.com/account'
										}
									>
									iPacket Account Site
									</a>{' '}
								</h2>
								<ul className="list-group list-group-flush py-2">
									<li className="list-group-item list-group-item-light">Your <b>Password</b></li>
									<li className="list-group-item list-group-item-light">Your <b>Mobile Number</b></li>
									<li className="list-group-item list-group-item-light">Your <b>Multi Factor Authentication (MFA)</b></li>
								</ul>
								<button className="btn btn-dark">
									<a
										className="light-link link-underline link-underline-opacity-10"
										target="_blank"
										href={
										window.location.hostname === 'dpapp.autoipacket.com'
											? 'https://auth.autoipacket.com/account'
											: 'https://auth.autoipacket-stg.com/account'
										}
									>
										Manage iPacket Account Settings
									</a>
								</button>
							</div>
						</div>

						<div className="card-text">

							<table className="table table-striped">
								<tbody>
									<tr><th colSpan={2}>{user.reset_password_token ? <UpdatePassword /> : ''}</th></tr>
									<tr>
										<th colSpan={2}>
											<button className="btn btn-sm btn-success" onClick={this.onClick}>
												<i className={'fal fa-envelope'} />
												{' '}
Send Password Reset
											</button>
											<DeleteUserButton {...this.props} user={user} />
										</th>
									</tr>
									<tr>
										<th>UserName</th>
										<td>{user.user_name}</td>
									</tr>
									<tr>
										<th>First Name</th>
										<td><EditUserInputs {...this.props} user_id={user.id} name={'given_name'} type="text" value={user.given_name} /></td>
									</tr>
									<tr>
										<th>Last Name</th>
										<td><EditUserInputs {...this.props} user_id={user.id} name={'family_name'} type="text" value={user.family_name} /></td>
									</tr>
									<tr>
										<th>Email</th>
										<td><EditUserInputs {...this.props} user_id={user.id} name={'email'} type="text" value={user.email} /></td>
									</tr>
									<tr>
										<th>Phone Number</th>
										<td><EditUserInputs {...this.props} user_id={user.id} name={'phone_number'} type="phone_number" value={user.phone_number} /></td>
									</tr>
									<tr>
										<th>Notification Default</th>
										<td><EditUserInputs {...this.props} user_id={user.id} name={'notification_preference'} type="select-notification" value={user.notification_preference} /></td>
									</tr>
									<tr>
										<th>Profile</th>
										<td data-title="Profile"><EditUserInputs key={`user_profile_${user.id}`} {...this.props} user_id={user.id} name={'position'} type="position" value={user.position} /></td>
									</tr>
									<tr>
										<th>Approve Estimates</th>
										<td data-title="Approve Estimates"><EditUserInputs key={`user_profile_${user.id}`} {...this.props} user_id={user.id} name={'approve_estimates'} type="switch" value={user.approve_estimates} /></td>
									</tr>
									<tr>
										<th>Dealerships</th>
										<td data-title="Dealerships"><EditUserInputs key={`user_dealer_${user.id}`} {...this.props} user_id={user.id} name={'dealerships'} type="dealerships_2" dealerships={user.user_installations} /></td>
									</tr>

								</tbody>
							</table>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

const mappedActions = {
	lookupUserById,
	forgotpassword,
	addFlashMessage,
	deleteFlashMessages,
	change_page,
	changepassword,
};

function mapStateToProps(state) {
	return {
		all_users: state.settings_users.all_users,
		searched_users: state.settings_users.searched_users,
		user_filter: state.settings_users.user_filter,
		single_user: state.settings_users.single_user,
		installation: state.settings_dealerships.installation,
	};
}

export default connect(mapStateToProps, mappedActions)(UserSingle);
